<script>
import WorkstationNavbar from '@/components/WorkstationNavbar.vue';
import { BusinessError } from '@/common/errors.js';

const { WMI_LIST } = window.config;

/**
 * @property {string} resultMessage
 * @property {string} errorMessage
 * @property {string} scanObject
 * @property {string} scanStep
 */
export default {
  name: 'WorkstationView',

  components: { WorkstationNavbar },

  data() {
    return {
      station: {
        id: '',
        name: '',
        description: '',
        type: '',
        isAutoRegistrationOrderMES: false,
        isError: false,
        status: '',
        vin: '',
        controlledParts: [],
        vinScanData: {
          log: [],
          isEndScanCycle: false,
          currentScanStep: '',
          lastResultMessage: '',
          nextScanStep: '',
          nextScanPrompt: '',
          nextVinPrompt: '',
        },
        partScanData: {
          log: [],
          isEndScanCycle: false,
          currentScanStep: '',
          lastResultMessage: '',
          nextScanStep: '',
          nextScanPrompt: '',
        },
        context: {
          order: '',
          partNumber: '',
        },
      },

      input: '',

      isLoading: false,
      isOrderRegisteredInMES: false,
    };
  },

  computed: {
    encodedStationId() {
      return encodeURIComponent(this.station.id);
    },

    stationStatusClass() {
      return this.station.isError ? 'status--error' : 'status--info';
    },

    isOrderJobLoaded() {
      return !!this.station.context.order;
    },

    isOrderJobSkipped() {
      return this.station.controlledParts.every(
        (controlledPart) => controlledPart.status === 'Skipped'
      );
    },

    isOrderJobCompleted() {
      const isAllControlledPartsProcessed = this.station.controlledParts.every(
        (controlledPart) =>
          controlledPart.status !== 'IsNotPlaced' &&
          controlledPart.status !== 'Error'
      );

      // Когда оператор сканирует заказ, а там уже установлены все детали,
      // ему все равно нужно отсканировать какую-нибудь деталь заказа,
      // чтобы ввести следующий заказ.
      // Поэтому надо ориентироваться на partScanData.isEndScanCycle
      // в проверке завершения работы с заказом.
      // В заказе, где ни одна деталь не устанавливается, для завершения работы
      // с заказом достаточно проверки isOrderJobSkipped, т.к. нам не нужно
      // сканировать деталь, чтобы перейти к следующему заказу

      return (
        (isAllControlledPartsProcessed &&
          this.station.partScanData.isEndScanCycle) ||
        this.isOrderJobSkipped
      );
    },
  },

  mounted() {
    this.setStationId();
    this.loadStation();
    this.$eventBus.$on('notification-added', this.removeFocusFromInput);
    this.$eventBus.$on('notification-deleted', this.setFocusOnInput);
  },

  beforeDestroy() {
    this.$eventBus.$off('notification-added', this.removeFocusFromInput);
    this.$eventBus.$off('notification-deleted', this.setFocusOnInput);
  },

  methods: {
    setStationId() {
      if (!this.station.id) {
        const path = window.location.pathname
          .split('/')
          .filter((item) => item !== '');
        this.station.id = path[path.length - 1];
      }
    },

    enableLoadingMode() {
      this.$preloader.show();
      this.isLoading = true;
      this.station.isError = false;
      this.station.status = '';
    },

    disableLoadingMode() {
      this.$preloader.hide();
      this.isLoading = false;
    },

    disableLoadingModeOnSuccess() {
      this.disableLoadingMode();
      this.$nextTick(() => {
        this.setFocusOnInput();
      });
    },

    loadStation() {
      this.enableLoadingMode();

      this.$api
        .getStation(this.encodedStationId)
        .then(this.initStation)
        .then(this.loadVinScanStepInfo)
        .then(this.disableLoadingModeOnSuccess)
        .catch(this.handleError);
    },

    loadVinScanStepInfo() {
      return this.$api
        .getVinScanStepInfo(this.encodedStationId)
        .then(this.processVinScanStepInfoResponse);
    },

    processVinScanStepInfoResponse(json) {
      const vinScanStepInfo = json['#value'];

      if (vinScanStepInfo.isEndScanCycle) {
        this.station.vinScanData.log = [];
      }

      this.station.vinScanData.isEndScanCycle = vinScanStepInfo.isEndScanCycle;
      this.station.vinScanData.lastResultMessage =
        vinScanStepInfo.resultMessage;
      this.station.vinScanData.nextScanPrompt = vinScanStepInfo.nextScanPrompt;
      if (this.station.vinScanData.nextVinPrompt === '') {
        this.station.vinScanData.nextVinPrompt = vinScanStepInfo.nextScanPrompt;
      }

      this.station.vinScanData.nextScanStep = vinScanStepInfo.nextScanStep;

      this.station.isError = vinScanStepInfo.isError;

      if (vinScanStepInfo.isError) {
        throw new BusinessError(vinScanStepInfo.resultMessage);
      }

      let statusMessage = '';
      if (vinScanStepInfo.resultMessage) {
        statusMessage = `${vinScanStepInfo.resultMessage}. `;
      }
      if (vinScanStepInfo.nextScanPrompt) {
        statusMessage += vinScanStepInfo.nextScanPrompt;
      }
      this.station.status = statusMessage;
    },

    loadOrder(vin) {
      this.enableLoadingMode();

      this.$api
        .getOrder(this.encodedStationId, vin)
        .then(this.processLoadOrderResponse)
        .then(this.postVinScanStepInfo)
        .then(this.loadControlledPartsOnVinScan)
        .then(this.loadPartScanStepInfo)
        .then(this.disableLoadingModeOnSuccess)
        .catch(this.handleOrderLoadError);
    },

    processLoadOrderResponse(json) {
      const vinScanData = json['#value'];
      if (vinScanData.isError) {
        throw new BusinessError(vinScanData.errorMessage);
      }
      this.station.isError = vinScanData.isError;
      this.station.context.order = vinScanData.scanObject;
      this.station.vinScanData.currentScanStep = vinScanData.scanStep;
      this.station.vin = vinScanData.barcode;

      this.station.vinScanData.log.push(vinScanData);
    },

    postVinScanStepInfo() {
      const requestBody = {};
      requestBody['#value'] = {
        context: [this.station.context.order],
        currentScanStep: this.station.vinScanData.currentScanStep,
        scanData: this.station.vinScanData.log,
      };

      return this.$api
        .postVinScanStepInfo(this.encodedStationId, requestBody)
        .then(this.processVinScanStepInfoResponse);
    },

    loadControlledPartsOnVinScan() {
      if (!this.station.vinScanData.isEndScanCycle) {
        return undefined;
      }

      return this.loadControlledParts();
    },

    loadControlledPartsOnPartScan() {
      if (!this.station.partScanData.isEndScanCycle) {
        return undefined;
      }

      return this.loadControlledParts();
    },

    loadControlledParts() {
      const encodedOrder = encodeURIComponent(this.station.context.order);

      return this.$api
        .getControlledParts(this.encodedStationId, encodedOrder)
        .then(this.setControlledParts);
    },

    setControlledParts(json) {
      this.station.controlledParts = json['#value'];
    },

    loadPartScanStepInfo() {
      if (!this.station.vinScanData.isEndScanCycle) {
        return undefined;
      }

      return this.$api
        .getPartScanStepInfo(this.encodedStationId)
        .then(this.processPartScanStepInfoResponse);
    },

    processPartScanStepInfoResponse(json) {
      const partScanStepInfo = json['#value'];

      if (partScanStepInfo.isEndScanCycle) {
        this.station.partScanData.log = [];
      }

      this.station.partScanData.isEndScanCycle =
        partScanStepInfo.isEndScanCycle;
      this.station.partScanData.lastResultMessage =
        partScanStepInfo.resultMessage;
      this.station.partScanData.nextScanPrompt =
        partScanStepInfo.nextScanPrompt;
      this.station.partScanData.nextScanStep = partScanStepInfo.nextScanStep;

      this.station.isError = partScanStepInfo.isError;

      if (partScanStepInfo.isError) {
        throw new BusinessError(partScanStepInfo.resultMessage);
      }

      let statusMessage = '';
      if (partScanStepInfo.resultMessage) {
        statusMessage = `${partScanStepInfo.resultMessage}. `;
      }
      if (partScanStepInfo.nextScanPrompt) {
        statusMessage += partScanStepInfo.nextScanPrompt;
      }
      this.station.status = statusMessage;
    },

    processControlledPart(barcode) {
      const encodedNextScanStep = encodeURIComponent(
        this.station.partScanData.nextScanStep
      );

      const previousBarcodes = this.station.partScanData.log.map((item) =>
        window
          .btoa(item.barcode)
          .replace(/\//g, '_')
          .replace(/\+/g, '-')
          .replace(/=/g, '')
      );

      let previousBarcodesQuery = '';

      if (previousBarcodes.length) {
        previousBarcodesQuery = `&previousBarcodes=${previousBarcodes.join()}`;
      }

      this.enableLoadingMode();

      this.$api
        .getPartScanData(
          this.encodedStationId,
          barcode,
          encodedNextScanStep,
          previousBarcodesQuery
        )
        .then(this.processPartScanResponse)
        .then(this.postPartScanStepInfo)
        .then(this.loadControlledPartsOnPartScan)
        .then(this.checkOrderJobCompletion)
        .then(this.disableLoadingModeOnSuccess)
        .catch(this.handleError);
    },

    processPartScanResponse(json) {
      const partScanData = json['#value'];
      if (partScanData.isError) {
        throw new BusinessError(partScanData.errorMessage);
      }

      this.station.context.partNumber = partScanData.scanObject;
      this.station.partScanData.currentScanStep = partScanData.scanStep;

      this.station.partScanData.log.push(partScanData);
    },

    postPartScanStepInfo() {
      const requestBody = {};
      requestBody['#value'] = {
        context: [this.station.context.order],
        currentScanStep: this.station.partScanData.currentScanStep,
        scanData: this.station.partScanData.log,
      };

      return this.$api
        .postPartScanStepInfo(this.encodedStationId, requestBody)
        .then(this.processPartScanStepInfoResponse);
    },

    checkOrderJobCompletion() {
      if (this.isOrderJobCompleted) {
        this.station.context.order = '';
        this.station.context.partNumber = '';
        let { lastResultMessage } = this.station.partScanData;
        if (lastResultMessage) {
          lastResultMessage += '. ';
        }
        this.station.status =
          lastResultMessage + this.station.vinScanData.nextVinPrompt;
      }
    },

    initStation(data) {
      this.station.id = data.id;
      this.station.name = data.name;
      this.station.description = data.description;
      this.station.type = data.type;
      this.station.controlledParts = data.controlledParts.map(
        (controlledPart) => ({ name: controlledPart, status: 'Initialized' })
      );
      this.station.isAutoRegistrationOrderMES =
        data.isAutoRegistrationOrderMES || false;
      this.station.vinMasks = (data.vinMasks || []).map((mask) => (new RegExp(mask)));
    },

    getControlledPartClass(status) {
      const classMap = {
        Initialized: 'controlled-parts__cell--initialized',
        IsNotPlaced: 'controlled-parts__cell--is-not-placed',
        IsPlaced: 'controlled-parts__cell--is-placed',
        Skipped: 'controlled-parts__cell--skipped',
      };
      return classMap[status];
    },

    onSubmitForm() {
      if (!this.input) {
        return;
      }

      this.isOrderRegisteredInMES = false;

      if (this.isVin(this.input)) {
        this.processVinInput(this.input);
      } else {
        this.processControlledPartInput(this.input);
      }
      this.clearInput();
    },

    processVinInput(vinInput) {
      if (!this.isOrderJobCompleted && this.isOrderJobLoaded) {
        this.station.isError = true;
        this.station.status = 'Необходимо закончить обработку текущего VIN';
        return;
      }
      this.loadOrder(vinInput);
    },

    processControlledPartInput(controlledPartInput) {
      if (!this.isOrderJobLoaded || this.isOrderJobSkipped) {
        this.resetOrderInfo();
        this.station.isError = true;
        this.station.status = 'Сначала необходимо ввести VIN';
        return;
      }
      this.processControlledPart(controlledPartInput);
    },

    resetOrderInfo() {
      this.station.context.order = '';
      this.station.context.partNumber = '';
      this.station.vin = '';
      this.station.controlledParts = this.station.controlledParts.map(
        (controlledPart) => {
          const controlledPartCopy = JSON.parse(JSON.stringify(controlledPart));
          controlledPartCopy.status = 'Initialized';
          controlledPartCopy.text = '';
          return controlledPartCopy;
        }
      );
    },

    isVin(string) {
      if (this.station.vinMasks.length > 0) {
        return this.station.vinMasks.some((mask) => (mask.test(string)));
      }

      if (WMI_LIST && WMI_LIST.length > 0) {
        const substring = string.slice(0, 3);
        return string.length === 17 && WMI_LIST.includes(substring);
      }

      const re = /^[A-HJ-NPR-Z\d]{13}\d{4}$/i;
      return re.test(string);
    },

    clearInput() {
      this.input = '';
    },

    onAppClick() {
      this.setFocusOnInput();
    },

    onRegistrationButtonClick() {
      const requestBody = {
        VIN: this.station.vin,
        orderCode: '',
      };

      this.enableLoadingMode();

      this.$api
        .registerOrder(this.encodedStationId, requestBody)
        .then((responseData) => {
          if (responseData.status && responseData.status.isError) {
            throw new BusinessError(responseData.status.message);
          }

          this.station.isError = false;
          this.station.status = 'Заказ зарегистрирован. Введите VIN';
          this.isOrderRegisteredInMES = true;
        })
        .then(this.disableLoadingModeOnSuccess)
        .catch(this.handleError);
    },

    setFocusOnInput() {
      if (this.$refs.formInput) {
        this.$refs.formInput.focus();
      }
    },

    removeFocusFromInput() {
      this.$refs.formInput.blur();
    },

    handleOrderLoadError(error) {
      this.resetOrderInfo();
      this.handleError(error);
    },

    handleError(error) {
      this.disableLoadingMode();

      if (error instanceof BusinessError) {
        this.station.isError = true;
        this.station.status = error.message;
        return;
      }

      this.$handleError(error);
    },
  },
};
</script>

<template>
  <div class="station" data-test="workstation-view" @click="onAppClick">
    <WorkstationNavbar :workstation="station" />
    <div
      v-if="station.status"
      class="status"
      :class="stationStatusClass"
      data-test="user-message"
    >
      {{ station.status }}
    </div>
    <form class="station__form form" @submit.prevent="onSubmitForm">
      <input
        id="formInput"
        ref="formInput"
        v-model="input"
        class="form__input"
        type="text"
        name="formInput"
        :disabled="isLoading"
        data-test="barcode-input"
      />
    </form>
    <div class="station__vin vin" data-test="vin-info">
      <span v-if="station.vin">VIN: {{ station.vin }}</span>
    </div>
    <ul
      v-if="station.controlledParts.length"
      class="station__controlled-parts controlled-parts"
    >
      <li
        v-for="controlledPart in station.controlledParts"
        :key="controlledPart.name"
        class="controlled-parts__item"
      >
        <div
          class="controlled-parts__cell"
          :class="getControlledPartClass(controlledPart.status)"
          data-test="part-name"
        >
          {{ controlledPart.name }}
        </div>
        <div
          class="controlled-parts__cell"
          :class="getControlledPartClass(controlledPart.status)"
        >
          {{ controlledPart.text }}
        </div>
      </li>
    </ul>
    <div
      v-if="
        station.isAutoRegistrationOrderMES &&
        isOrderJobCompleted &&
        !isOrderRegisteredInMES
      "
      class="button-wrapper"
    >
      <button
        class="button button--registration"
        data-test="order-registration-button"
        @click="onRegistrationButtonClick"
      >
        Зарегистрировать заказ в MES
      </button>
    </div>
  </div>
</template>

<style scoped>
.station {
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow-x: hidden;
}

.station__vin {
  min-height: 40px;
  margin-top: 10px;
  font-size: 30px;
  color: white;
  overflow-wrap: break-word;
}

.station__controlled-parts {
  margin: 0;
  padding: 0;
  font-size: 30px;
  list-style: none;
}

.controlled-parts__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.controlled-parts__cell {
  -webkit-flex-basis: 50%;
  flex-basis: 50%;
  margin: 1px;
  padding: 10px;
  word-break: break-word;
}

/* noinspection CssUnusedSymbol */
.controlled-parts__cell--initialized {
  background-color: lightgray;
}

/* noinspection CssUnusedSymbol */
.controlled-parts__cell--is-not-placed {
  background-color: white;
}

/* noinspection CssUnusedSymbol */
.controlled-parts__cell--is-placed {
  background-color: lime;
}

/* noinspection CssUnusedSymbol */
.controlled-parts__cell--skipped {
  background-color: lime;
}

.button-wrapper {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
}

.button {
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 29px;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.button--registration {
  width: 95%;
  height: 4rem;
  font-size: 1.5rem;
  background-color: #3371b3;
  border-color: #2a5c92;
  color: white;
}

.button--registration:not(:disabled):hover {
  background-color: #2e66a1;
}
</style>
