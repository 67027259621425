<script>
import WorkstationSearchView from '@/components/WorkstationSearchView.vue';
import WorkstationsView from '@/components/WorkstationsView.vue';
import WorkstationView from '@/components/WorkstationView.vue';
import LoginView from '@/components/LoginView.vue';

export default {
  name: 'AppRouter',

  data() {
    return {
      currentPath: window.location.pathname,
    };
  },

  computed: {
    routedComponent() {
      if (this.currentPath.match(/^\/$/)) {
        return WorkstationSearchView;
      }

      if (this.currentPath.match(/^\/workstations(\/|)$/)) {
        return WorkstationsView;
      }

      if (this.currentPath.match(/^\/station(\/|)(\w|-)+$/)) {
        return WorkstationView;
      }

      if (this.currentPath.match(/^\/login(\/|)$/)) {
        return LoginView;
      }

      return null;
    },
  },

  created() {
    this.$router.listen((route) => {
      this.currentPath = route;
    });

    window.addEventListener('popstate', () => {
      this.currentPath = window.location.pathname;
    });
  },
};
</script>

<template>
  <component :is="routedComponent" v-if="routedComponent" :key="currentPath" />
</template>
