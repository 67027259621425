<script>
export default {
  name: 'AppNotifications',

  data() {
    return {
      notifications: [],
      notificationLiveTime: window.config.NOTIFICATION_LIVE_TIME || 5000,
    };
  },

  created() {
    this.$eventBus.$on('createNotification', this.addNotification);
  },

  methods: {
    addNotification(notification) {
      const uniqueNotification = notification;
      uniqueNotification.id = Date.now();

      this.notifications.push(uniqueNotification);
      this.$eventBus.$emit('notification-added');

      setTimeout(() => {
        this.deleteNotification(uniqueNotification.id);
      }, this.notificationLiveTime);
    },

    deleteNotification(notificationId) {
      this.notifications = this.notifications.filter(
        (notification) => notification.id !== notificationId
      );
      if (this.notifications.length === 0) {
        this.$eventBus.$emit('notification-deleted');
      }
    },
  },
};
</script>

<template>
  <div
    v-if="notifications.length"
    class="notification__wrapper"
    data-test="notifications-wrapper"
  >
    <div
      v-for="(notification, i) in notifications"
      :key="i"
      :class="`notification notification--${notification.type}`"
    >
      <span>{{ notification.text }}</span>
    </div>
  </div>
</template>

<style scoped>
.notification {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  font-size: 2rem;
  color: white;
  border-bottom: 2px solid transparent;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
}

.notification__wrapper {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 10px;
}

.notification--error {
  border-color: #e53935;
  background: #e57373;
}

@media (max-width: 768px) {
  .notification__wrapper {
    width: 100%;
  }
}
</style>
