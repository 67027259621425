import Vue from 'vue';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
import { ServerError, NetworkError } from '@/common/errors.js';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const checkStatus = (response, requestBody) => {
  if (response.status === 204) {
    return undefined;
  }

  if (response.status >= 200 && response.status < 300) {
    return response.json().then((json) => {
      if (!json) {
        throw new ServerError(
          'Пришел пустой ответ от сервера',
          response.status,
          response.url
        );
      }
      return json;
    });
  }

  if (response.status === 401) {
    throw new ServerError(
      'Пользователь не авторизован',
      response.status,
      response.url,
      requestBody
    );
  }

  const contentType = response.headers.get('Content-Type');

  if (contentType && contentType.indexOf('text/plain') !== -1) {
    return response.text().then((text) => {
      if (
        text.indexOf('Startup of infobase session is not allowed') !== -1 ||
        text.indexOf('Начало сеанса с информационной базой запрещено') !== -1
      ) {
        throw new ServerError(
          'Проводятся регламентные работы',
          response.status,
          response.url,
          requestBody
        );
      }
      throw new ServerError(text, response.status, response.url, requestBody);
    });
  }

  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json().then((json) => {
      throw new ServerError(
        json.error.message,
        response.status,
        response.url,
        requestBody
      );
    });
  }

  if (contentType && contentType.indexOf('application/xml') !== -1) {
    return response.text().then((xml) => {
      throw new ServerError(xml, response.status, response.url, requestBody);
    });
  }

  throw new ServerError(
    response.statusText,
    response.status,
    response.url,
    requestBody
  );
};

export const handleError = (error, notifier, router) => {
  console.error(error);

  if (window.config.SENTRY_URL && error.requestBody) {
    Sentry.addBreadcrumb({
      message: `URL: ${error.url}; Request body: ${error.requestBody}`,
      level: 'error',
    });
  }

  if (window.config.SENTRY_URL) {
    Sentry.captureException(error);
  }

  let errorMessage = error.message;

  if (error instanceof ServerError || error instanceof NetworkError) {
    const keys = ['message', 'code', 'url'];

    errorMessage = keys.reduce((acc, key) => {
      if (error[key]) {
        return `${acc}${key}: ${error[key]}\n`;
      }
      return acc;
    }, '');
  }

  if (error.code === 401) {
    router.goTo('/login');
    return;
  }

  if (notifier) {
    notifier.error(errorMessage);
  }
};

export const getEncodedBarcode = (barcode, vm) => {
  let encodedBarcode = '';

  try {
    encodedBarcode = window
      .btoa(barcode)
      .replace(/\//g, '_')
      .replace(/\+/g, '-')
      .replace(/=/g, '');
  } catch (error) {
    handleError(error, vm.$notifier);
  }

  return encodedBarcode;
};

export const getFixedLayoutString = (str) => {
  let newString = str;

  const layoutMap = {
    'й': 'q',
    'ц': 'w',
    'у': 'e',
    'к': 'r',
    'е': 't',
    'н': 'y',
    'г': 'u',
    'ш': 'i',
    'щ': 'o',
    'з': 'p',
    'х': '[',
    'ъ': ']',
    'ф': 'a',
    'ы': 's',
    'в': 'd',
    'а': 'f',
    'п': 'g',
    'р': 'h',
    'о': 'j',
    'л': 'k',
    'д': 'l',
    'ж': ';',
    'э': "'",
    'я': 'z',
    'ч': 'x',
    'с': 'c',
    'м': 'v',
    'и': 'b',
    'т': 'n',
    'ь': 'm',
    'б': ',',
    'ю': '.',
    '.': '/',
  };

  for (let i = 0; i < str.length; i += 1) {
    const l = str[i];

    if (layoutMap[l.toLowerCase()] !== undefined) {
      let replace = '';

      if (l === l.toLowerCase()) {
        replace = layoutMap[l.toLowerCase()];
      } else if (l === l.toUpperCase()) {
        replace = layoutMap[l.toLowerCase()].toUpperCase();
      }

      newString = newString.replace(l, replace);
    }
  }

  return newString;
};

export const initSentry = (dsn, env, release) => {
  const DEFAULT_ENVIRONMENT = 'testing';
  const ALLOWED_ENVIRONMENTS = ['production', 'testing', 'development'];

  try {
    const environment = env || DEFAULT_ENVIRONMENT;

    if (ALLOWED_ENVIRONMENTS.indexOf(environment) === -1) {
      throw Error(
        `Значение окружения ENVIRONMENT (${environment}) заполнено неверно, используйте одно из следующих значений: ${ALLOWED_ENVIRONMENTS.join(
          ', '
        )}`
      );
    }

    Sentry.init({
      Vue,
      environment,
      dsn,
      release,
      integrations: [new BrowserTracing()],
    });
  } catch (error) {
    window.globals.sentryInitError = error;
  }
};
