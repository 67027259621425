<script>
import appVersion from '@/common/version.js';

export default {
  name: 'AppVersion',

  data() {
    return {
      appVersion,
    };
  },
};
</script>

<template>
  <div v-if="appVersion" class="app-version">ver. {{ appVersion }}</div>
</template>

<style scoped>
.app-version {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 90;
  padding: 1rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: bold;
  color: white;
}
</style>
