<script>
import AppRouter from '@/components/AppRouter.vue';
import AppNotifications from '@/components/AppNotifications.vue';
import AppPreloader from '@/components/AppPreloader.vue';
import AppVersion from '@/components/AppVersion.vue';

export default {
  name: 'App',

  components: {
    AppRouter,
    AppNotifications,
    AppPreloader,
    AppVersion,
  },

  created() {
    if (this.$auth) {
      this.processAuthActions();
    }
  },

  mounted() {
    if (window.globals.sentryInitError) {
      this.$notifier.error(`Sentry error: ${window.globals.sentryInitError}`);
    }
  },

  methods: {
    processAuthActions() {
      window.onfocus = () => {
        if (
          this.$auth.isAccessTokenExists() &&
          !this.$auth.isAccessTokenExpired()
        ) {
          return;
        }

        if (
          this.$auth.isRefreshTokenExists() &&
          !this.$auth.isRefreshTokenExpired()
        ) {
          this.$auth.refreshToken();
        } else {
          this.$router.goTo('/login');
        }
      };

      if (
        this.$auth.isAccessTokenExists() &&
        !this.$auth.isAccessTokenExpired()
      ) {
        this.$auth.setSentryUserInfo();
      } else if (
        this.$auth.isRefreshTokenExists() &&
        !this.$auth.isRefreshTokenExpired()
      ) {
        this.$auth.refreshToken().then(() => {
          this.$auth.setSentryUserInfo();
        });
      } else {
        this.$router.goTo('/login');
      }
    },
  },
};
</script>

<template>
  <div id="app">
    <AppRouter /><AppNotifications /><AppPreloader /><AppVersion />
  </div>
</template>

<style scoped>
#app {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: flex;
  position: relative;
  min-height: 620px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-weight: bold;
}
</style>
