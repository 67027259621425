<script>
import AppDropdown from '@/components/AppDropdown.vue';

export default {
  name: 'WorkstationNavbar',

  components: { AppDropdown },

  props: {
    workstation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      workstations: [],
      isWorkstationsDropdownShown: false,
    };
  },

  computed: {
    workstationsDropdownList() {
      return this.workstations.map((it) => ({
        text: it.name,
        callback: () => {
          this.$router.goTo(`/station/${it.id}`);
        },
      }));
    },
  },

  created() {
    window.addEventListener('click', this.onClickOutsideDropdown);
    this.loadWorkstations();
  },

  beforeDestroy() {
    window.removeEventListener('click', this.onClickOutsideDropdown);
  },

  methods: {
    loadWorkstations() {
      this.$api
        .getWorkstations()
        .then((responseData) => {
          this.workstations = responseData;
        })
        .catch(this.$handleError);
    },

    onWorkstationsButtonClick() {
      this.isWorkstationsDropdownShown = !this.isWorkstationsDropdownShown;
    },

    onHomeButtonClick() {
      this.$router.goTo('/');
    },

    onClickOutsideDropdown(e) {
      const path = e.path || (e.composedPath && e.composedPath());
      const hasButtonWrapperAncestor = path.some(
        (it) => it.className === 'button__wrapper--navbar'
      );
      if (!hasButtonWrapperAncestor) {
        this.isWorkstationsDropdownShown = false;
      }
    },

    onExitButtonClick() {
      this.$auth.logout();
    },
  },
};
</script>

<template>
  <div class="navbar" data-test="workstation-navbar">
    <button
      class="navbar__item button button--navbar button--home"
      @click.stop="onHomeButtonClick"
    >
      <img src="@/assets/img/home.svg" alt="home" />
      <span class="button__text">Главная страница</span>
    </button>

    <div class="navbar__item button__wrapper--navbar">
      <button
        :disabled="!workstations.length"
        class="button button--navbar button--workstations"
        data-test="navbar-workstations-menu-button"
        @click.stop="onWorkstationsButtonClick"
      >
        <img src="@/assets/img/workstation.svg" alt="workstations" />
        <span class="button__text">Рабочие места</span>
      </button>
      <AppDropdown
        v-show="isWorkstationsDropdownShown && workstationsDropdownList"
        :list="workstationsDropdownList"
        data-test="navbar-workstations-menu"
        @dropdown-item-click="onWorkstationsButtonClick"
      />
    </div>

    <button
      v-if="$auth"
      class="navbar__item button button--navbar button--exit"
      data-test="exit-button"
      @click.stop="onExitButtonClick"
    >
      <img src="@/assets/img/exit.svg" alt="exit" />
      <span class="button__text">Выход</span>
    </button>
  </div>
</template>

<style scoped>
.button {
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  line-height: 29px;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.button > img,
.button > span {
  vertical-align: middle;
}

.button:disabled {
  cursor: default;
  opacity: 0.6;
}

.navbar {
  height: 3rem;
  margin-bottom: 1rem;
}

.navbar__item {
  width: 32.8%;
}

.navbar__item:not(:last-child) {
  margin-right: 0.8%;
}

.button--workstations {
  width: 100%;
}

.button--navbar {
  font-size: 1.1rem;
  height: 100%;
  background-color: #3371b3;
  border-color: #2a5c92;
}

.button--navbar:not(:disabled):hover {
  background-color: #2e66a1;
}

.button__wrapper--navbar {
  height: 100%;
  display: inline-block;
  position: relative;
}

.button__text {
  margin-left: 10px;
}

@media (max-width: 768px) {
  .navbar .button__text {
    display: none;
  }
}
</style>
