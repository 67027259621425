export class ServerError extends Error {
  constructor(message, code, url) {
    super(message);
    this.name = this.constructor.name;
    this.code = code;
    this.url = url;
  }

  toString() {
    return this.message;
  }
}

export class NetworkError extends Error {
  constructor(message, url) {
    super(message);
    this.name = this.constructor.name;
    this.url = url;
  }

  toString() {
    return this.message;
  }
}

export class BusinessError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }

  toString() {
    return this.message;
  }
}
