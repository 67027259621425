const listeners = [];
let routeBeforeLogin = '/';

export default class Router {
  goTo(route) {
    if (route === window.location.pathname) {
      return;
    }

    if (route === '/login') {
      routeBeforeLogin = window.location.pathname;
    }

    const previousRoute = window.location.pathname;
    window.history.pushState(null, null, route);

    if (window.navigator.userAgent.indexOf('iPhone OS 4_3') !== -1) {
      window.location.hash = '!';
      window.location.hash = '';
    }

    listeners.forEach((listener) => listener(route, previousRoute));
  }

  listen(fn) {
    listeners.push(fn);
  }

  goBack() {
    this.goTo(routeBeforeLogin);
  }
}
