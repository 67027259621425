<script>
export default {
  name: 'AppDropdown',

  props: {
    list: {
      type: Array,
      default: () => [],
    },

    onRightSide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    dropdownListClasses() {
      return {
        'dropdown__list': true,
        'dropdown__list--right-side': this.onRightSide,
      };
    },
  },

  methods: {
    onListItemClick(item) {
      item.callback();
      this.$emit('dropdown-item-click');
    },
  },
};
</script>

<template>
  <ul v-if="list.length" :class="dropdownListClasses">
    <li v-for="item in list" :key="item.text" class="dropdown__item">
      <button
        class="button button--navbar-dropdown"
        @click.stop="onListItemClick(item)"
      >
        {{ item.text }}
      </button>
    </li>
  </ul>
</template>

<style scoped>
.button {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 6px 12px;
  margin-bottom: 0;
  font-weight: normal;
  color: white;
  text-align: center;
  line-height: 1.4285;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.button:disabled {
  cursor: default;
  opacity: 0.6;
}

.dropdown__item {
  width: 100%;
  display: -webkit-box;
  display: flex;
}

.dropdown__list {
  z-index: 9000;
  position: absolute;
  list-style: none;
  min-width: 100%;
  overflow-x: hidden;
  top: auto;
  left: 0;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.dropdown__list--right-side {
  right: 0;
}

.button--navbar-dropdown {
  width: 100%;
  font-size: 1.1rem;
  border-radius: 0;
  background-color: #3371b3;
  border-color: #2a5c92;
  -webkit-box-pack: left;
  justify-content: left;
}

.button--navbar-dropdown:not(:disabled):hover {
  background-color: #2e66a1;
}
</style>
