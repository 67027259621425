import customFetch from '@/common/fetch.js';
import * as Sentry from '@sentry/browser';

const FORM_CONTENT_TYPE = 'application/x-www-form-urlencoded';

export default class Auth {
  constructor(url, clientId, realm, router) {
    this.url = url;
    this.clientId = clientId;
    this.realm = realm;
    this.router = router;
  }

  login(username, password) {
    localStorage.clear();
    return this.getToken(username, password);
  }

  logout() {
    const refreshToken = this.getRefreshTokenFromLocalStorage();

    const formBody = [
      `client_id=${this.clientId}`,
      `refresh_token=${refreshToken}`,
    ].join('&');

    return customFetch(
      `${this.url}/realms/${this.realm}/protocol/openid-connect/logout`,
      {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': FORM_CONTENT_TYPE,
        },
      }
    ).then(() => {
      localStorage.clear();
      this.router.goTo('/login');
    });
  }

  getToken(username, password) {
    const formBody = [
      `client_id=${this.clientId}`,
      `username=${username}`,
      `password=${password}`,
      `grant_type=password`,
    ].join('&');

    return customFetch(
      `${this.url}/realms/${this.realm}/protocol/openid-connect/token`,
      {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': FORM_CONTENT_TYPE,
        },
      }
    )
      .then(this.setStore)
      .catch((error) => {
        console.error(error);
        localStorage.clear();
        this.router.goTo('/login');
      });
  }

  refreshToken() {
    const refreshToken = this.getRefreshTokenFromLocalStorage();

    const formBody = [
      `client_id=${this.clientId}`,
      `refresh_token=${refreshToken}`,
      `grant_type=refresh_token`,
    ].join('&');

    return customFetch(
      `${this.url}/realms/${this.realm}/protocol/openid-connect/token`,
      {
        method: 'POST',
        body: formBody,
        headers: {
          'Content-Type': FORM_CONTENT_TYPE,
        },
      }
    )
      .then(this.setStore)
      .catch((error) => {
        console.error(error);
        localStorage.clear();
        this.router.goTo('/login');
      });
  }

  setSentryUserInfo() {
    const accessToken = this.getAccessToken();

    return customFetch(
      `${this.url}/realms/${this.realm}/protocol/openid-connect/userinfo`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    ).then((responseData) => {
      Sentry.configureScope((scope) => {
        scope.setTag('realm', this.realm);
        scope.setUser({
          username: responseData.preferred_username,
          realm: this.realm,
        });
      });
    });
  }

  setStore(data) {
    localStorage.setItem('access_token', data.access_token);
    localStorage.setItem('refresh_token', data.refresh_token);
    localStorage.setItem(
      'access_expires_at',
      new Date().getTime() + data.expires_in * 1000
    );
    localStorage.setItem(
      'refresh_expires_at',
      new Date().getTime() + data.refresh_expires_in * 1000
    );

    localStorage.setItem(
      'access_expires_at_date',
      new Date(new Date().getTime() + data.expires_in * 1000)
    );
    localStorage.setItem(
      'refresh_expires_at_date',
      new Date(new Date().getTime() + data.refresh_expires_in * 1000)
    );
  }

  getAccessToken() {
    if (this.isAccessTokenExists() && !this.isAccessTokenExpired()) {
      return this.getAccessTokenFromLocalStorage();
    }

    if (this.isRefreshTokenExists() && !this.isRefreshTokenExpired()) {
      return this.refreshToken().then(this.getAccessTokenFromLocalStorage);
    }

    return '';
  }

  isAccessTokenExpired() {
    const now = new Date();
    const accessExpiresAt = localStorage.getItem('access_expires_at');
    return now > parseInt(accessExpiresAt, 10);
  }

  isRefreshTokenExpired() {
    const now = new Date();
    const refreshExpiresAt = localStorage.getItem('refresh_expires_at');
    return now > parseInt(refreshExpiresAt, 10);
  }

  isAccessTokenExists() {
    return !!this.getAccessTokenFromLocalStorage();
  }

  isRefreshTokenExists() {
    return !!this.getRefreshTokenFromLocalStorage();
  }

  getRefreshTokenFromLocalStorage() {
    return localStorage.getItem('refresh_token');
  }

  getAccessTokenFromLocalStorage() {
    return localStorage.getItem('access_token');
  }
}
