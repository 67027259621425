<script>
export default {
  name: 'AppPreloader',

  data() {
    return {
      isShown: false,
      counter: 0,
    };
  },

  created() {
    this.$eventBus.$on('preloader', this.onPreloaderEvent);
  },

  methods: {
    onPreloaderEvent(isShown) {
      if (isShown) {
        this.counter += 1;
      }

      if (!isShown && this.counter > 0) {
        this.counter -= 1;
      }

      if (this.counter > 0) {
        this.isShown = true;
      } else {
        this.isShown = false;
      }
    },
  },
};
</script>

<template>
  <div v-show="isShown" class="preloader-wrapper">
    <div class="preloader" />
  </div>
</template>

<style scoped>
.preloader-wrapper {
  left: 0;
  top: 0;
  z-index: 9997;
  width: 100%;
  height: 100%;
  position: fixed;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.preloader,
.preloader::before,
.preloader::after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation: load7 1.8s infinite ease-in-out both;
  animation: load7 1.8s infinite ease-in-out both;
}

.preloader {
  color: #4288d3;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.preloader::before,
.preloader::after {
  content: '';
  position: absolute;
  top: 0;
}

.preloader::before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.preloader::after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 2.5em 0 -1.3em;
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    -webkit-box-shadow: 0 2.5em 0 0;
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    -webkit-box-shadow: 0 2.5em 0 -1.3em;
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    -webkit-box-shadow: 0 2.5em 0 0;
    box-shadow: 0 2.5em 0 0;
  }
}
</style>
