<script>
export default {
  name: 'WorkstationsView',

  data() {
    return {
      workstations: [],
      isLoading: false,
    };
  },

  mounted() {
    this.loadWorkstations();
  },

  methods: {
    loadWorkstations() {
      this.enableLoadingMode();

      this.$api
        .getWorkstations()
        .then((workstations) => {
          if (workstations.length === 1) {
            this.openWorkstationView(workstations[0].id);
          } else {
            this.workstations = workstations;
          }
        })
        .finally(this.disableLoadingMode)
        .catch(this.$handleError);
    },

    enableLoadingMode() {
      this.$preloader.show();
      this.isLoading = true;
    },

    disableLoadingMode() {
      this.$preloader.hide();
      this.isLoading = false;
    },

    openWorkstationView(workstationId) {
      this.$router.goTo(`/station/${workstationId}`);
    },

    onBackButtonClick() {
      this.$router.goTo('/');
    },

    onWorkstationButtonClick(workstationId) {
      this.openWorkstationView(workstationId);
    },
  },
};
</script>

<template>
  <div
    v-if="!isLoading"
    class="station-list__content"
    data-test="workstations-view"
  >
    <button
      class="button button--back"
      data-test="back-home-button"
      @click="onBackButtonClick"
    >
      Назад
    </button>
    <ul v-if="workstations.length" class="stations__list">
      <li
        v-for="workstation in workstations"
        :key="workstation.id"
        class="stations__item station"
      >
        <button
          class="station__link"
          data-test="workstation-button"
          @click="onWorkstationButtonClick(workstation.id)"
        >
          <div>
            <div>{{ workstation.name }}</div>
            <div>{{ workstation.description }}</div>
          </div>
        </button>
      </li>
    </ul>
    <div v-else class="workstations__empty" data-test="workstations-empty">
      Нет рабочих мест
    </div>
  </div>
</template>

<style scoped>
.station-list__content {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.stations__list {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: 10px;
  list-style: none;
}

.stations__item {
  display: -webkit-box;
  display: flex;
  margin: 0.3%;
  min-height: 90px;
  width: 32.7%;
  background-color: #ef5300;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.station__link {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex: 1;
  padding: 1rem;
  font-size: 35px;
  text-align: center;
  background-color: #ef5300;
  cursor: pointer;
  color: white;
}

.station__link div {
  margin: 0 auto;
}

.workstations__empty {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 2rem;
  color: white;
}

.button--back {
  font-size: xx-large;
  background-color: #000080;
}

@media only screen and (max-width: 600px) {
  .stations__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .stations__item {
    width: auto;
  }
}
</style>
