export default class Notifier {
  constructor(eventBus) {
    this.eventBus = eventBus;
  }

  emitCreateNotificationEvent(text, type) {
    this.eventBus.$emit('createNotification', { text, type });
  }

  error(text) {
    this.emitCreateNotificationEvent(text, 'error');
  }
}
