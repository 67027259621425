<script>
import { getFixedLayoutString } from '@/common/helpers.js';

export default {
  name: 'LoginView',

  data() {
    return {
      username: '',
      password: '',
      userMessage: '',
      isLoading: false,
    };
  },

  mounted() {
    this.setUsernameFocus();
  },

  methods: {
    onLoginButtonClick() {
      this.login();
    },

    login() {
      this.resetUserMessage();

      if (!this.username || !this.password) {
        this.resetForm();
        this.userMessage = 'Не введён логин или пароль';
        return;
      }

      const cyrillicPattern = /[\u0400-\u04FF]+/;

      if (cyrillicPattern.test(this.username)) {
        this.username = getFixedLayoutString(this.username);
      }

      if (cyrillicPattern.test(this.password)) {
        this.password = getFixedLayoutString(this.password);
      }

      this.isLoading = true;

      this.$auth
        .login(this.username, this.password)
        .then(() => {
          if (window.config.SENTRY_URL) {
            this.$auth.setSentryUserInfo();
          }
          this.$router.goBack();
        })
        .catch((error) => {
          this.isLoading = false;
          this.resetForm();
          if (error.code === 401) {
            this.userMessage = 'Неверный логин или пароль';
            return;
          }
          this.$handleError(error);
        });
    },

    setUsernameFocus() {
      this.$refs.username.focus();
    },

    resetUserMessage() {
      this.userMessage = '';
    },

    resetForm() {
      this.username = '';
      this.password = '';
      this.setUsernameFocus();
    },

    getLogoImageUrl() {
      if (!window.config.PLANT_ID) {
        return '';
      }

      const map = {
        moskvich: 'moskvich.png',
      };

      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`../assets/img/${map[window.config.PLANT_ID]}`);
    },
  },
};
</script>

<template>
  <div class="login" @click="setUsernameFocus">
    <div class="login-wrapper">
      <div
        v-if="getLogoImageUrl()"
        class="logo-image-wrapper"
        data-test="plant-logo-block"
      >
        <img :src="getLogoImageUrl()" class="logo-image" alt="logo" />
      </div>

      <div class="login__interface-name" data-test="interface-name">
        QFS Error Proofing
      </div>

      <div class="login-form" data-test="login-form">
        <div class="login__input-wrapper" @click.stop>
          <label class="login__label" for="username">Логин</label>
          <input
            id="username"
            ref="username"
            v-model="username"
            class="form__input form__input--username"
            type="text"
            data-test="username-input"
          />
        </div>
        <div class="login__input-wrapper" @click.stop>
          <label class="login__label" for="password">Пароль</label>
          <input
            id="password"
            v-model="password"
            class="form__input form__input--password"
            type="password"
            data-test="password-input"
            @keyup.enter="login"
          />
        </div>

        <button
          class="button button--login"
          :disabled="isLoading"
          data-test="login-button"
          @click.stop="onLoginButtonClick"
        >
          Вход
        </button>

        <div
          v-if="userMessage"
          class="login__message"
          data-test="login-message"
        >
          {{ userMessage }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.login {
  -webkit-box-flex: 1;
  flex-grow: 1;
}

.login-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  height: 100%;
}

.login__interface-name {
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  color: white;
}

.logo-image-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
}

.logo-image {
  height: auto;
  width: auto;
  max-width: 250px;
  max-height: 250px;
}

.login__input-wrapper {
  margin-bottom: 1.5rem;
}

.login__label {
  text-align: center;
  display: block;
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  color: white;
}

.form__input {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form__input:focus {
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form__input[readonly] {
  background-color: white;
}

.form__input--username,
.form__input--password {
  width: 100%;
  font-size: 1.8rem;
  padding: 0.5rem;
}

.button--login {
  width: 100%;
  margin-top: 10px;
  padding: 1rem;
  font-size: 2rem;
  background-color: #000080;
}

.button--login:disabled {
  opacity: 0.5;
}

.login__message {
  font-size: x-large;
  margin-top: 1rem;
  padding: 2rem;
  text-align: center;
  color: white;
  border-color: #e53935;
  background: #e57373;
}

@media (min-width: 768px) {
  .login {
    margin: 4rem auto;
    -webkit-box-flex: 0;
    flex-grow: 0;
    width: 500px;
  }
}
</style>
