<script>
export default {
  name: 'WorkstationSearchView',

  data() {
    return {
      barcode: '',

      status: {
        isError: false,
        text: 'Отсканируйте код рабочего места',
      },

      isLoading: false,
    };
  },

  computed: {
    statusClass() {
      return this.status.isError ? 'status--error' : 'status--info';
    },
  },

  mounted() {
    this.setFocusOnInput();
    this.$eventBus.$on('notification-added', this.removeFocusFromInput);
    this.$eventBus.$on('notification-deleted', this.setFocusOnInput);
  },

  beforeDestroy() {
    this.$eventBus.$off('notification-added', this.removeFocusFromInput);
    this.$eventBus.$off('notification-deleted', this.setFocusOnInput);
  },

  methods: {
    onEnter() {
      this.enableLoadingMode();

      const encodedStationId = encodeURIComponent(this.barcode);

      this.$api
        .getStation(encodedStationId)
        .then((workstation) => {
          this.openWorkstationView(workstation.id);
        })
        .finally(() => {
          this.disableLoadingMode();
        })
        .catch(this.handleError);
    },

    enableLoadingMode() {
      this.$preloader.show();
      this.isLoading = true;
      this.status.isError = false;
      this.status.text = '';
    },

    disableLoadingMode() {
      this.$preloader.hide();
      this.isLoading = false;
      this.status.text = '';
    },

    openWorkstationView(workstationId) {
      this.$router.goTo(`/station/${workstationId}`);
    },

    onWorkstationsListButtonClick() {
      this.$router.goTo('/workstations');
    },

    onPageClick() {
      this.setFocusOnInput();
    },

    showError(text) {
      this.status.isError = true;
      this.status.text = text;
    },

    setFocusOnInput() {
      this.$refs.formInput.focus();
    },

    removeFocusFromInput() {
      this.$refs.formInput.blur();
    },

    handleError(error) {
      if (error.code === 404) {
        this.showError(`Рабочее место ${this.barcode} не найдено`);
        this.barcode = '';
        this.setFocusOnInput();
        return;
      }

      this.$handleError(error);
    },
  },
};
</script>

<template>
  <div
    class="station-search__content"
    data-test="workstation-search-view"
    @click="onPageClick"
  >
    <div
      v-show="status.text"
      class="status"
      :class="statusClass"
      data-test="user-message"
    >
      {{ status.text }}
    </div>
    <input
      id="formInput"
      ref="formInput"
      v-model="barcode"
      class="form__input"
      type="text"
      name="formInput"
      :disabled="isLoading"
      data-test="workstation-search-input"
      @keyup.enter="onEnter"
    />
    <button
      class="button button--open-station-list"
      data-test="workstations-list-button"
      @click.stop="onWorkstationsListButtonClick"
    >
      Перейти к списку рабочих мест
    </button>
  </div>
</template>

<style scoped>
.station-search__content {
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.button--open-station-list {
  margin-top: 10px;
  font-size: x-large;
  background-color: #ef5300;
}
</style>
