import customFetch from '@/common/fetch.js';

const { SERVER_URL } = window.config;

export default class Http {
  constructor(auth) {
    this.auth = auth;
  }

  async get(url, headers) {
    let customHeaders = headers;

    if (this.auth) {
      const token = await this.auth.getAccessToken();

      if (token) {
        customHeaders = { ...headers, Authorization: `Bearer ${token}` };
      }
    }

    return customFetch(`${SERVER_URL}${url}`, {
      method: 'get',
      credentials: 'include',
      headers: customHeaders,
    });
  }

  async post(url, body, customServerUrl) {
    let headers = { 'Content-Type': 'application/json' };

    if (this.auth) {
      const token = await this.auth.getAccessToken();

      if (token) {
        headers = { ...headers, Authorization: `Bearer ${token}` };
      }
    }

    return customFetch(`${customServerUrl || SERVER_URL}${url}`, {
      method: 'post',
      credentials: 'include',
      body: JSON.stringify(body),
      headers,
    });
  }
}
