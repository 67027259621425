import { checkStatus } from '@/common/helpers.js';
import { NetworkError } from '@/common/errors.js';

const DEFAULT_REQUEST_TIMEOUT = 60000;

export default (url, options) =>
  new Promise((resolve, reject) => {
    const requestTimeout =
      window.config.REQUEST_TIMEOUT || DEFAULT_REQUEST_TIMEOUT;

    setTimeout(() => {
      reject(new NetworkError('Request timeout', url));
    }, requestTimeout);

    fetch(url, options).then(resolve, (err) => {
      reject(new NetworkError(err, url));
    });
  }).then((response) => checkStatus(response, options.body));
