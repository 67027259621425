import Vue from 'vue';
import App from '@/components/App.vue';
import { initSentry, handleError } from '@/common/helpers.js';
import vuePlugins from '@/common/vuePlugins.js';
import appVersion from '@/common/version.js';
import '@/assets/css/style.css';
import 'whatwg-fetch';

window.globals = {};

Vue.config.productionTip = false;

Vue.config.errorHandler = (error, vm) => {
  handleError(error, vm.$notifier);
};

if (window.config.SENTRY_URL) {
  const defaultAppVersion = '0.0.0';
  initSentry(
    window.config.SENTRY_URL,
    window.config.ENVIRONMENT,
    appVersion || defaultAppVersion
  );
}

Vue.use(vuePlugins);

new Vue({ render: (h) => h(App) }).$mount('#app');
