import Http from '@/common/http.js';
import { getEncodedBarcode } from '@/common/helpers.js';

const { MES_SERVER_URL } = window.config;

export default class ApiService {
  constructor(auth) {
    this.http = new Http(auth);
  }

  getWorkstations() {
    return this.http.get('/stations/2.1?basic');
  }

  getStation(stationId) {
    return this.http.get(`/stations/2.1/${stationId}`);
  }

  getVinScanStepInfo(stationId) {
    return this.http.get(`/ierrorproofing/${stationId}/vinscanstepinfo`);
  }

  getOrder(stationId, vin) {
    const requestHeaders = { 'Barcode-Encoding': 'Base64' };
    const encodedVIN = getEncodedBarcode(vin);

    return this.http.get(
      `/ierrorproofing/${stationId}/vinscandata/${encodedVIN}`,
      requestHeaders
    );
  }

  postVinScanStepInfo(stationId, requestBody) {
    return this.http.post(
      `/ierrorproofing/${stationId}/vinscanstepinfo`,
      requestBody
    );
  }

  getControlledParts(stationId, order) {
    return this.http.get(
      `/ierrorproofing/orders/${order}/controlledParts?stationId=${stationId}`
    );
  }

  getPartScanStepInfo(stationId) {
    return this.http.get(`/ierrorproofing/${stationId}/partscanstepinfo`);
  }

  getPartScanData(stationId, barcode, nextScanStep, previousBarcodesQuery) {
    const requestHeaders = { 'Barcode-Encoding': 'Base64' };
    const encodedBarcode = getEncodedBarcode(barcode);

    return this.http.get(
      `/ierrorproofing/${stationId}/partscandata/${encodedBarcode}?scanStep=${nextScanStep}${previousBarcodesQuery}`,
      requestHeaders
    );
  }

  postPartScanStepInfo(stationId, requestBody) {
    return this.http.post(
      `/ierrorproofing/${stationId}/partscanstepinfo`,
      requestBody
    );
  }

  registerOrder(stationId, requestBody) {
    return this.http.post(
      `/iequipment/SubmitQuery/1.0/${stationId}`,
      requestBody,
      MES_SERVER_URL
    );
  }
}
