import { render, staticRenderFns } from "./AppDropdown.vue?vue&type=template&id=2bc7148f&scoped=true"
import script from "./AppDropdown.vue?vue&type=script&lang=js"
export * from "./AppDropdown.vue?vue&type=script&lang=js"
import style0 from "./AppDropdown.vue?vue&type=style&index=0&id=2bc7148f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc7148f",
  null
  
)

export default component.exports