export default class Preloader {
  constructor(eventBus) {
    this.eventBus = eventBus;
  }

  emitPreloaderEvent(isShown) {
    this.eventBus.$emit('preloader', isShown);
  }

  show() {
    this.emitPreloaderEvent(true);
  }

  hide() {
    this.emitPreloaderEvent(false);
  }
}
