import Vue from 'vue';
import { handleError } from '@/common/helpers.js';
import ApiService from '@/common/api.js';
import Notifier from '@/common/notifier.js';
import Preloader from '@/common/preloader.js';
import Router from '@/common/router.js';
import Auth from '@/common/auth.js';

const eventBus = new Vue();
const notifierInstance = new Notifier(eventBus);
const routerInstance = new Router();

let authInstance = null;

if (window.config.AUTH_SERVER_URL) {
  authInstance = new Auth(
    window.config.AUTH_SERVER_URL,
    window.config.AUTH_SERVER_CLIENT_ID,
    window.config.AUTH_SERVER_REALM,
    routerInstance
  );
}

export default {
  // eslint-disable-next-line no-shadow
  install(Vue) {
    Vue.mixin({
      computed: {
        $api: () => new ApiService(authInstance),
        $eventBus: () => eventBus,
        $notifier: () => notifierInstance,
        $preloader: () => new Preloader(eventBus),
        $router: () => routerInstance,
        $auth: () => authInstance,
      },
      methods: {
        $handleError(error) {
          handleError(error, notifierInstance, routerInstance);
        },
      },
    });
  },
};
